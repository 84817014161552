<template>
  <div class="tooltip" :class="[setTooltip]">
    <button class="tooltip-btn" type="button" @click="isMobile ? clicked() : null" @mouseover="!isMobile ? mouseovered() : null" @mouseleave="mouseleaved()" ref="btn"><i class="aikon aikon-help tooltip-btn-icon"></i></button>
    <div class="tooltip-shadow">
      <div class="tooltip-content" :class="[classNames, { 'is-show': show }]" ref="msg">
        <p class="tooltip-msg">{{ tooltipMsg }}</p>
        <button class="tooltip-close" type="button" v-on:click="close" v-if="isMobile"></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  data: function() {
    return {
      isMobile: false,
      show: false,
    };
  },
  props: {
    tooltipMsg: String,
    tooltipPosition: String,
    classNames: String,
  },
  mounted: function() {
    this.updateTooltipPosition();
  },
  methods: {
    //tooltip
    clicked: function() {
      this.show = !this.show;
      // console.log('クリック');
      // console.log(this.tooltipMsg);
    },
    mouseovered: function() {
      this.show = true;
      // console.log('ホバー');
      // console.log(this.tooltipMsg);
    },
    mouseleaved: function() {
      this.show = false;
    },
    close: function() {
      this.show = false;
    },
    updateTooltipPosition: function() {
      const btnWidth = this.$refs.btn.getBoundingClientRect().width;
      const ttWidth = this.$refs.msg.getBoundingClientRect().width;
      const position = btnWidth / 2 - ttWidth / 2;
      this.$refs.msg.style.left = position + 'px';
    },
  },
  watch: {
    tooltipMsg: function() {
      this.$nextTick(() => {
        this.updateTooltipPosition();
      });
    },
  },
  computed: {
    isTouchDevice: function() {
      if (window.ontouchstart === null || this.isMobile) {
        return true;
      } else {
        return false;
      }
    },
    setTooltip: function() {
      return `tooltip-${this.tooltipPosition}`
    },
  },
};
</script>
