<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :isSettingShown="$permission.isGmo() && isGmoOrOfficeUrl" :handleClick="openRoleModal"/>
      <section class="section" v-if="isBoardShown">
        <div class="row">
          <div v-for="item in dataBoard" :key="item.index" class="col">
            <Panel type="board" :color="item.color" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay>
                  <template v-slot:title>{{ item.title }}</template>
                  <template v-slot:num>{{ item.number }}</template>
                  <template v-slot:sup>{{ item.subTitle }}</template>
                  <template v-if="item.subNumber" v-slot:sub>{{ item.subNumber }}</template>
                </NumDisplay>
              </template>
              <template v-slot:tooltip>
                <Tooltip
                  tooltipPosition="bottomLeft"
                  :tooltipMsg="item.tooltipMsg"
                />
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section" v-if="isShowPointBoard && isBoardShown">
        <div class="row">
          <div v-for="item in dataPointBoard" :key="item.index" class="col">
            <Panel type="board" :color="item.color" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay>
                  <template v-slot:title>{{ item.title }}</template>
                  <template v-slot:num>{{ item.number }}</template>
                  <template v-slot:sup>{{ item.subTitle }}</template>
                </NumDisplay>
              </template>
              <template v-slot:tooltip>
                <Tooltip
                  tooltipPosition="bottomLeft"
                  :tooltipMsg="item.tooltipMsg"
                />
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:headline>最近の利用状況3件</template>
              <template v-slot:headerContent>
                <router-link
                  v-if="!isHistoryPaymentNotSeen"
                  class="btn btn-sm btn-black pos-end"
                  :to="{ name: historyRouteName }"
                >詳細を見る</router-link>
              </template>
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :dataTable="dataTable"
                    :labels="labels"
                    :unAuthorized="!hasTransactionRole || isHistoryPaymentNotSeen"
                  ></TableWithCheckbox>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <Modal @close="closeModal('roleModal')" v-if="roleModal">
      <template v-slot:headline>
        表示設定
      </template>
      <template v-slot:body>
        <div class="form-header d-flex justify-content-between align-items-center">
          <span class="selectList-description">区分</span>
          <span class="selectList-description">表示</span>
        </div>
        <FormRow v-for="(role, index) in userRoleData" :key="index" :isHeaderRow="true">
          <template v-slot:label>{{ role.label }}</template>
          <template v-slot:toggle>
            <label class="form-toggle">
              <input
                class="form-toggle-input"
                type="checkbox"
                :value="role.value"
                v-model="formData.invisibleDashboard"
              />
              <span class="form-toggle-set">
                <span class="form-toggle-bg"></span>
                <span class="form-toggle-circle"></span>
              </span>
            </label>
          </template>
        </FormRow>
      </template>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <button
              class="btn btn-white"
              type="button"
              @click="closeModal('roleModal')"
            >
              キャンセル
            </button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-main" :handle-submit="submitRole" button-text="登録する" />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//constants
import { StatusConstants } from '@/constants/status';
import { EventConstants } from '@/constants/event';
import { RoleConstants } from '@/constants/role';
//helpers
import { formatDateAndTime, formatLocalString, breakLineWithComma, convertStringToNumberArray } from '@/helpers/formatData';
//mixins
import modal from '@/mixins/plugin/modal';
//components
import NumDisplay from '@/components/NumDisplay.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import Tooltip from '@/components/Tooltip.vue';
import Modal from '@/components/Modal.vue';
import FormRow from '@/components/FormRow.vue';

export default {
  name: 'EventDashboardParent',
  data: function () {
    return {
      pageName: 'ダッシュボード',
      userRoleData: StatusConstants.userRoles,
      roleModal: false,
      formData: {
        invisibleDashboard: StatusConstants.userRoles.map(e => e.value),
      }
    };
  },
  mixins: [modal],
  components: {
    NumDisplay,
    TableWithCheckbox,
    Tooltip,
    Modal,
    FormRow,
  },
  computed: {
    ...mapGetters({
      infor: 'auth/infor',
      historyPayments: 'history/historyPayments',
      isHistoryPaymentNotSeen: 'history/isHistoryPaymentNotSeen',
      childEventAggregate: 'aggregate/childEventAggregate',
      isShowCustomer: 'event/isShowCustomer',
      hasCustomersRole: 'auth/hasCustomersRole',
      hasShopRole: 'auth/hasShopRole',
      newSubdomain: 'common/subdomain',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
      isGmoOrOfficeLoginStoreAndStoreGroup: 'common/isGmoOrOfficeLoginStoreAndStoreGroup',
      isUsePointFlagChildEvent: 'event/isUsePointFlagChildEvent',
      isUsePointFlagParentEvent: 'event/isUsePointFlagParentEvent',
      canUseCpmParentEvent: 'event/canUseCpmParentEvent',
      isClientChild: 'auth/isClientChild',
      isOnlyChildEvent: 'event/isOnlyChildEvent',
      isGmoOrOfficeUrl: 'common/isGmoOrOfficeUrl',
      childEventDetail: 'event/childEventDetail',
      useTicketFlag: 'event/useTicketFlag',
      useTicketFlagNumber: 'event/useTicketFlagNumber',
      eventGroups: 'event/eventGroups',
      hasTransactionRole: 'auth/hasTransactionRole',
      hasCurrency: 'event/hasCurrency',
    }),
    historyRouteName() {
      if (this.isGmoOrOfficeLoginStoreAndStoreGroup) {
        return 'ShopHistory'
      }
      return 'EventHistoryUse'
    },
    isShowPointBoard() {
      const isUsePoint = this.isUsePointFlagParentEvent && this.isUsePointFlagChildEvent && this.canUseCpmParentEvent;
      return isUsePoint && (this.isClientChild ? this.isOnlyChildEvent : this.$permission.isGmoOrOffice()) && !this.isGmoOrOfficeLoginStore;
    },
    amountTextTooltip() {
      const chargeText = this.isChargeType ? (this.hasCurrency ? '上段：顧客が支払った金額の合計\n下段：上段の金額に割増率を掛けた金額の合計+通貨管理にて発行された金額の合計\nカッコ内は入金待ちの金額の合計' :
        '上段：顧客が支払った金額の合計\n下段：上段の金額に割増率を掛けた金額の合計\nカッコ内は入金待ちの金額の合計') : '通貨管理にて発行された金額の合計';
      const chargeTicketText = this.isTicketChargeType ? (this.hasCurrency ? '上段：顧客の購入金額（実際に支払った金額）の合計\n下段：TICKET内訳金額の合計+通貨管理にて付与されたTICKET金額の合計\nカッコ内は入金待ちの金額の合計' :
        '上段：顧客の購入金額（実際に支払った金額）の合計\n下段：TICKET内訳金額の合計\nカッコ内は入金待ちの金額の合計') : '通貨管理にて付与されたTICKET金額の合計';
      return this.useTicketFlag ? chargeTicketText : chargeText;
    },
    currencyTooltipText() {
      if (this.hasCurrency) {
        if (this.childEventDetail?.hasPaymentPoint) {
          return '上段：顧客が利用した金額の合計+通貨管理にて消費された金額の合計-ポイントでの支払い分\n下段：上段の件数';
        }
        return '上段：顧客が利用した金額の合計+通貨管理にて消費された金額の合計\n下段：上段の件数';
      }
      return this.childEventDetail?.hasPaymentPoint ? '上段：顧客が利用した金額の合計-ポイントでの支払い分\n下段：上段の件数' : '上段：顧客が利用した金額の合計\n下段：上段の件数';
    },
    amountMoneyTooltip() {
      return this.useTicketFlag ? (this.hasCurrency ? '上段：顧客が利用した金額の合計+通貨管理にて消費された金額の合計\n下段：上段の件数' : '上段：顧客が利用した金額の合計\n下段：上段の件数') : this.currencyTooltipText;
    },
    chargeTooltip() {
      const chargeTicketText = this.isTicketChargeType ? (this.hasCurrency ? 'TICKET購入・付与金額の合計-利用累計額-失効累計額の合計' : 'TICKET購入金額の合計-利用累計額-失効累計額の合計') : 'TICKET付与金額-利用累計額-失効累計額の合計';
      return this.useTicketFlag ? chargeTicketText : 'チャージ金額-利用累計額-失効累計額の合計';
    },
    paymentTooltip() {
      return this.useTicketFlag ? '有効期限切れ、退会して失効したTICKET金額の合計' : '有効期限切れ、退会して失効した金額の合計';
    },
    isChargeType() {
      return this.childEventDetail?.type == StatusConstants.currency.parent.charge.store.value;
    },
    isPaymentType() {
      return this.childEventDetail?.type == StatusConstants.currency.parent.payment.cancel.value;
    },
    isTicketChargeType() {
      return this.childEventDetail?.type == EventConstants.CHARGE_GIFT_CARD_EVENT_TYPE;
    },
    isTicketPaymentType() {
      return this.childEventDetail?.type == StatusConstants.chargeDateType.ticketPayment.value;
    },
    isPayment() {
      return this.isPaymentType || this.isTicketPaymentType;
    },
    dataBoard() {
      let unavailableAmountText = '';
      let unavailableChargedText = '';
      if (this.childEventAggregate.charges.unavailableAmount) {
        unavailableAmountText = '（'+ formatLocalString(this.childEventAggregate.charges.unavailableAmount) + '円）';
        unavailableChargedText = '（'+ formatLocalString(this.childEventAggregate.charges.unavailableCharged) + '円）';
      }
      return [
        {
          color: 'blue',
          title: this.useTicketFlag ? (this.isTicketPaymentType ? 'TICKET付与金額' : (this.hasCurrency ? 'TICKET購入・付与金額' : 'TICKET購入金額')) : 'チャージ金額',
          number: formatLocalString(this.isPayment ? this.childEventAggregate.charges.charged : this.childEventAggregate.charges.amount),
          subTitle: '円' + unavailableAmountText,
          subNumber: this.isPayment ? '' : formatLocalString(this.childEventAggregate.charges.charged - this.childEventAggregate.payments.cancel) + '円' + unavailableChargedText,
          tooltipMsg: breakLineWithComma(this.amountTextTooltip)
        },
        {
          color: 'green',
          title: '利用累計額',
          number: formatLocalString(this.childEventAggregate.payments.amountMoney),
          subTitle: '円',
          subNumber: formatLocalString(this.childEventAggregate.payments.count) + '件',
          tooltipMsg: breakLineWithComma(this.amountMoneyTooltip)
        },
        {
          color: 'red',
          title: this.useTicketFlag ? 'TICKET残高' : 'チャージ残高',
          number: formatLocalString(this.childEventAggregate.charges.charged - this.childEventAggregate.payments.amountMoney - this.childEventAggregate.payments.expired - this.childEventAggregate.payments.cancel),
          subTitle: '円',
          subNumber: false,
          tooltipMsg: breakLineWithComma(this.chargeTooltip)
        },
        {
          color: 'yellow',
          title: '失効累計額',
          number: formatLocalString(this.childEventAggregate.payments.expired),
          subTitle: '円',
          subNumber: false,
          tooltipMsg: breakLineWithComma(this.paymentTooltip)
        },
      ]
    },
    dataPointBoard() {
      return [
        {
          color: 'purple',
          title: '発行済みポイント累計',
          number: formatLocalString(this.childEventAggregate?.point?.charged),
          subTitle: 'P',
          subNumber: false,
          tooltipMsg: breakLineWithComma('全期間のポイント付与の合計')
        },
        {
          color: 'deeppink',
          title: '使用済みポイント累計',
          number: formatLocalString(this.childEventAggregate?.point?.payment),
          subTitle: 'P',
          subNumber: false,
          tooltipMsg: breakLineWithComma('全期間のポイント消費の合計')
        },
        {
          color: 'red',
          title: 'ポイント残高',
          number: formatLocalString(this.childEventAggregate?.point?.charged - this.childEventAggregate?.point?.payment - this.childEventAggregate?.point?.expired),
          subTitle: 'P',
          subNumber: false,
          tooltipMsg: breakLineWithComma('発行済みポイント累計-使用済みポイント累計-ポイント失効累計の合計')
        },
        {
          color: 'yellow',
          title: '失効ポイント累計',
          number: formatLocalString(this.childEventAggregate?.point?.expired),
          subTitle: 'P',
          subNumber: false,
          tooltipMsg: breakLineWithComma('全期間の有効期限切れ、退会・削除して失効したポイントの合計')
        },
      ]
    },
    dataTable() {
      return this.historyPayments.map((item) => {
        return {
          ...item,
          userId: item?.numberPut ? breakLineWithComma((item.numberPut + ',') + item?.userId) : item?.userId,
          shopName: item.shop?.name,
          groupName: item.shop?.groupName,
          createDate: formatDateAndTime(item.createDate),
          amount: '¥' + formatLocalString(item.amount),
        };
      });
    },
    subdomain() {
      if (this.$permission.isStoreOrGroup()) {
        return this.infor.event?.subdomain || null;
      } else {
        return (this.isGmoOrOfficeLoginStore ? this.newSubdomain : this.$route.params?.subdomain) || null;
      }
    },
    labels() {
      return [
        { key: 'shopName', name: '店舗名', notShow: !this.hasShopRole },
        { key: 'createDate', name: '利用日時' },
        { key: 'amount', name: '利用金額' },
        { key: 'name', name: 'お名前', notShow: (this.isGmoOrOfficeLoginStoreAndStoreGroup && !this.isShowCustomer) || !this.hasCustomersRole },
        { key: 'userId', name: 'user ID', tdClass: 'pre-line' },
        { key: 'groupName', name: 'グループ名', notShow: this.useTicketFlag || !this.eventGroups?.length },
      ]
    },
    isBoardShown() {
      let newKind;
      if (this.$permission.isOffice()) {
        newKind = this.isClientChild ? RoleConstants.ROLE_CLIENT_CHILD: RoleConstants.ROLE_CLIENT_PARENT;
      } else {
        newKind = this.infor?.kind;
      }
      return this.childEventDetail?.invisibleDashboard?.includes(Number(newKind));
    }
  },
  methods: {
    openRoleModal() {
      this.showModal('roleModal');
    },
    async submitRole() {
      const data = convertStringToNumberArray(this.formData.invisibleDashboard);
      const response = await this.$store.dispatch('event/setInvisibleDashboard', {
        invisibleDashboard: data
      });
      if (response?.data?.result) {
        this.$message.showMessageSuccess(response?.data?.message);
        await this.$store.dispatch('event/getChildEventDetail', { subdomain: this.subdomain });
        this.closeModal('roleModal');
      }
    },
    getHistoryPayment() {
      if (this.useTicketFlagNumber === undefined) return;
      const path = this.useTicketFlagNumber ? 'history/getHistoryPaymentsTicket' : 'history/getHistoryPayments';
      this.$store.dispatch(path, {
        limit: 3,
        subdomain: this.subdomain,
        config: {
          isWithNotSeenHistoryPayment: true,
        },
        hasShop: 1,
      })
    }
  },
  mounted() {
    const loading = this.$loading.show();
    Promise.all([
      this.$store.dispatch('event/getChildEventDetail', { subdomain: this.subdomain }),
      this.$store.dispatch('aggregate/getChildEventAggregate', { subdomain: this.subdomain }),
      this.getHistoryPayment(),
    ]).finally(() => {
      this.formData.invisibleDashboard = this.childEventDetail?.invisibleDashboard || [];
      this.$loading.clear(loading);
    })
  },
  watch: {
    useTicketFlag(){
      this.getHistoryPayment();
    }
  }
};
</script>

<style scoped>
.form-header {
  padding: 16px 32px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #e1e2eb;
}
</style>
